import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import menuScripts from './menu-scripts';
import animatedCircle from './animated-circle';
import homeAnimationTrigger from './home-animation-trigger';

import focus from '@alpinejs/focus';
import resize from '@alpinejs/resize';
import intersect from '@alpinejs/intersect';

Alpine.plugin(focus);
Alpine.plugin(resize);
Alpine.plugin(intersect);

window.Alpine = Alpine;
window.htmx = htmx;

Alpine.data("menuScripts", menuScripts);
Alpine.data("animatedCircle", animatedCircle);
Alpine.data("homeAnimationTrigger", homeAnimationTrigger);

if(nonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = nonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});